@import './utils/variables';
@import './utils/mixins';
@import './utils/extend';
@import './utils/placeholders';
@import './utils/functions';

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: url('./fonts/Montserrat-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('./fonts/Montserrat-SemiBold.ttf') format('truetype');
}
