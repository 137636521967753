//----------------------------//
//        MEASUREMENTS        //
//----------------------------//

$header-height: 4.8rem;

//----------------------------//
//           COLORS           //
//----------------------------//

// Shades of blue
$color-blue-primary: #2962ff;
$color-blue-primary-dark: #0039cb;
$color-blue-text: #002c9c;

$color-malibu: #5dbfee;
$color-pacific-blue: #0098db;
$color-bahama-blue: #13548a;
$color-prussian-blue: #043272;

// Shades of black

// Shades of gray
$color-gray-text: #6a7985;
$color-sidemenu-gray-light: #546273;
$color-sidemenu-gray: #3f4853;
$color-black-text: #222;

$color-white: #fff;
$color-gray-94: #f0f0f0;
$color-button-gray-hover: #e8e8e8;
$color-button-gray-active: #d4d4d4;
$color-silver: #c6c6c6;

$color-black-dark: #2d2d2d;
$color-black: #000;

// Shades of red
$color-red-secondary: #ff2946;
$color-red-secondary-dark: #c4001f;

$color-red-wrong: #b00020;

// Shades of green:
$color-green-right: #06990a;
$color-green-switch: #52d869;

// Main colors
$color-primary: $color-bahama-blue;
$color-nav: $color-primary;

//----------------------------//
//          SHADOWS           //
//----------------------------//

$shadow-left-table-fixed: -3px 2px 5px 0px rgba(0, 0, 0, 0.2);
$shadow-bottom-table-fixed: 4px 3px 5px 0px rgba(0, 0, 0, 0.2);
