@import '../../sass/main.scss';

.SideNav {
    background-color: $color-sidemenu-gray;
    display: flex;
    flex-direction: column;

    &Item {
        color: $color-white;
        padding: 1.6rem 4rem 1.6rem 0;
        transition: all 0.2s ease;
        width: 100%;

        &:hover {
            background-color: $color-sidemenu-gray-light;
            text-decoration: none;
        }

        &Active {
            background-color: $color-blue-primary;
            color: $color-white;

            &:hover {
                background-color: $color-blue-primary;
            }
        }

        svg {
            fill: currentColor;
            height: 1.6rem;
            margin-left: 2rem;
            margin-right: 1rem;
            width: auto;
        }

        &Container {
            display: flex;
        }
    }
}

.Logo {
    align-self: center;
    display: flex;
    height: 6rem;
    margin: 1rem 0;
    width: 6rem;
}
