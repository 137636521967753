@import '../../../../sass/main.scss';

.Footer {
    background-color: $color-nav;
    color: $color-white;
    padding-top: 3rem;
    padding-bottom: 3rem;

    &Group {
        &Title {
            font-weight: 600;
            padding-bottom: 1rem;
        }

        &Text {
            font-size: 1.4rem;
        }
    }

    &Icon {
        margin-right: 1rem;
    }

    &Map {
        width: 100%;
        height: 16rem;
        object-fit: none;
    }

    /* Begin IE 11 styles */
    &Grid {
        @extend %clearfix;

        .FooterGroup1,
        .FooterGroup2,
        .FooterGroup3,
        .FooterGroup4,
        .FooterGroup5 {
            float: left;
            width: 33%;
        }
    }

    &Group4,
    &Group5,
    &Group6,
    &Map {
        padding-top: 3rem;
    }

    &Group6 {
        width: 70rem;
        margin: 0 auto;
    }
    /* End IE 11 styles */

    @supports (display: grid) {
        /* Begin cleaning IE 11 styles */
        &Grid {
            @extend %remove-clearfix;

            .FooterGroup1,
            .FooterGroup2,
            .FooterGroup3,
            .FooterGroup4,
            .FooterGroup5 {
                float: unset;
                width: unset;
            }
        }

        &Group4,
        &Group5,
        &Group6,
        &Map {
            padding-top: unset;
        }

        &Group6 {
            width: unset;
            margin: unset;
        }
        /* End cleaning IE 11 styles */

        &Grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
            row-gap: 3rem;
        }

        &Group {
            &1 {
                grid-row: 1 / 2;
                grid-column: 1 / 2;
            }
            &2 {
                grid-row: 2 / 3;
                grid-column: 1 / 2;
            }
            &3 {
                grid-row: 1 / 2;
                grid-column: 2 / 3;
            }
            &4 {
                grid-row: 2 / 3;
                grid-column: 2 / 3;
            }
            &5 {
                grid-row: 1 / 2;
                grid-column: 3 / 4;
            }
            &6 {
                grid-row: 2 / 3;
                grid-column: 3 / 4;
            }

            @include respond(tab-port) {
                &5 {
                    grid-row: 3 / 4;
                    grid-column: 1 / 3;
                }
                &6 {
                    grid-row: 4 / 5;
                    grid-column: 1 / 3;
                }
            }

            @media only screen and (max-width: 25em) {
                // 400px
                &3 {
                    grid-row: 3 / 4;
                    grid-column: 1 / 2;
                }
                &4 {
                    grid-row: 4 / 5;
                    grid-column: 1 / 2;
                }
                &5 {
                    grid-row: 5 / 6;
                    grid-column: 1 / 2;
                }
                &6 {
                    grid-row: 6 / 7;
                    grid-column: 1 / 2;
                }
            }
        }
    }
}

.Address {
    display: grid;
    grid-template-columns: min-content 1fr;

    .FooterGroupText {
        max-width: 25rem;

        @include respond(tab-port) {
            max-width: unset;
        }
    }
}

.Link {
    color: $color-white;
    &:hover {
        color: $color-white;
    }
}
