@import '../../sass/main.scss';

.Content {
    margin: 0 auto;
    width: 100%;
    max-width: 120rem;

    @include respond(tab-land) {
        max-width: none;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.Loading {
    position: absolute;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    top: 0;

    &Spin {
        height: 5rem;
        width: 5rem;
        color: $color-primary;
    }
}
