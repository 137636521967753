@import '../../sass/main.scss';

.Loading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    top: 0;

    &Spin {
        height: 5rem;
        width: 5rem;
        color: $color-primary;
    }
}

.Page {
    display: grid;
    height: 100vh;
    grid-template-columns: [sidenav-start] auto [sidenav-end content-start] 1fr [content-end];

    &Content {
        background-color: $color-gray-94;
        padding: 3rem;
        height: 100vh;
        overflow-x: auto;    
    }
}