@import '../../../../sass/main.scss';

.Header {
    display: flex;
    height: $header-height;
    width: 100%;
    color: $color-white;
    background-color: $color-nav;
    position: fixed;
    top: 0;
    left: 0;
    z-index: z('header');

    .Content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        position: relative;
    }
}

.LogoContainer {
    display: flex;
    align-items: center;
}

.Logo {
    height: $header-height - 1rem;
    width: $header-height - 1rem;
}
