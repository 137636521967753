$form-input-border-height: 1px;

%background-gray {
    background-color: $color-gray-94;
}

%form-input {
    border: $color-silver solid $form-input-border-height;
    height: 4rem;
    padding: 0.4rem 1rem 0 1rem;
    width: 100%;

    &::placeholder {
        visibility: hidden;
        opacity: 0;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* Begin IE10+ styles */
            visibility: visible;
            opacity: 1;
            /* End IE10+ styles */
        }
    }

    &:focus {
        @extend %form-input-focused;
    }

    &:placeholder-shown:not(:focus) + label {
        font-size: 1.6rem;
        transform: translateY(1.8rem);
    }

    &:-webkit-autofill + label {
        font-size: 1.4rem;
        transform: translateY(0);
    }

    &:invalid {
        @extend %form-input-invalid;
    }
}

%form-input-focused {
    border-width: 2px;
    border-color: $color-primary;
    outline: none;

    & + label,
    & + label + span {
        color: $color-primary;
    }
}

%form-input-invalid {
    border-color: $color-red-wrong;

    &:focus {
        border-color: $color-red-wrong;

        & + label,
        & + label + span {
            color: $color-red-wrong;
        }
    }

    & + label,
    & + label + span {
        color: $color-red-wrong;
    }
}

%form-input-error-message {
    color: $color-red-wrong;
    display: block;
    font-size: 1.4rem;
    padding-top: 0.3rem;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* Begin IE10+ styles */
        margin-bottom: 2rem;
        /* End IE10+ styles */
    }

    &:empty:before {
        content: '\200b';
    }
}

%form-label {
    background-color: $color-white;
    color: $color-black-text;
    position: absolute;
    top: -0.8rem;
    left: 1rem;
    font-size: 1.4rem;
    padding: 0 0.6rem;
    transition: transform 0.3s, font-size 0.3s;
    pointer-events: none;
}

%clearfix {
    &:before,
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

%remove-clearfix {
    &:before,
    &:after {
        content: unset;
        display: unset;
        clear: unset;
    }
}
