@import '../../sass/main.scss';

$nav-item-border-margin: 1rem;
$nav-item-border-height: 0.5rem;
$nav-item-margin: $nav-item-border-margin + $nav-item-border-height;

$side-menu-transition-time: 0.9s;

.NavigationContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.Navigation {
    font-size: 1.4rem;
    right: 0;
    position: absolute;
    height: 100%;
    display: flex;
    align-items: flex-end;

    @include respond(tab-port) {
        align-items: center;
        text-transform: uppercase;
    }
}

.NavigationItem {
    color: $color-white;
    display: inline-block;
    transition: color 0.3s ease-in-out;
    padding-top: 3rem; // To allow above the label to be clickable

    &:hover {
        color: $color-silver;
        text-decoration: none;
    }
    &:not(:last-child) {
        margin-right: 4rem;
    }

    &::after {
        content: '';
        display: block;
        margin-top: $nav-item-margin;
        transform: scaleX(0);
        transition: transform 0.25s ease;
    }

    &.NavigationItemActive::after {
        content: '';
        display: block;
        border-bottom: solid $nav-item-border-height $color-pacific-blue;
        margin-top: $nav-item-border-margin;
        transform: scaleX(1);
    }
}

// Responsive Styles for Navigation

.NavigationBackdrop {
    @include respond(tab-port) {
        background-color: $color-black;
        opacity: 0;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        transition: opacity $side-menu-transition-time ease;
        pointer-events: none;

        &Active {
            display: inline-block;
            opacity: 0.7;
            pointer-events: all;
        }
    }
}

.NavigationHamburger {
    display: none;
    z-index: 1;

    @include respond(tab-port) {
        display: flex;
        height: 50%;
        align-items: center;
        cursor: pointer;

        &:hover,
        &:focus {
            .NavigationHamburgerIcon {
                transform: scale(1.1);
            }
        }
    }

    &Icon {
        @include respond(tab-port) {
            cursor: pointer;
            position: relative;
            transition: transform 0.2s ease;

            &,
            &::before,
            &::after {
                cursor: pointer;
                width: 3rem;
                height: 2px;
                border: none;
                background-color: $color-white;
                display: inline-block;
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                left: 0;
                transition: all 0.4s;
            }

            &::before {
                top: -0.8rem;
            }
            &::after {
                top: 0.8rem;
            }
        }
    }

    &IconClose {
        @include respond(tab-port) {
            background-color: transparent;

            &::before {
                top: 0;
                transform: rotate(135deg);
            }
            &::after {
                top: 0;
                transform: rotate(-135deg);
            }
        }
    }
}

.NavigationSideMenu {
    @include respond(tab-port) {
        background-color: $color-nav;
        position: fixed;
        height: 100vh;
        width: 50vw;
        top: 0;
        right: -70vw;
        padding-top: $header-height;
        transition: right $side-menu-transition-time ease;

        .NavigationItem {
            background-color: $color-white;
            color: $color-black;
            width: 100%;
            border: $color-silver 0.1rem solid;
            margin: 0;
            padding: 3rem 3rem 1rem 3rem;
            transition: background-color 0.2s ease-in-out;

            &:hover {
                background-color: $color-gray-94;
            }

            &::after {
                transition: transform 0.4s ease;
            }

            &.NavigationItemActive::after {
                transform-origin: 0% 50%;
            }
        }

        @include respond(phone) {
            width: 70vw;
        }
    }

    &Open {
        @include respond(tab-port) {
            top: 0;
            right: 0;
        }
    }
}
