@import '../main.scss';

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%; // 1rem = 10px

    @include respond(tab-land) {
        font-size: 56.25%; // 1rem = 9px
    }

    @include respond(tab-port) {
        font-size: 50%; // 1rem = 8px
    }

    @include respond(big-desktop) {
        font-size: 75%; // 1rem = 12px
    }
}

body {
    font-size: 1.6rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    box-sizing: border-box;
}

input,
textarea,
button {
    font-family: inherit;
}

input,
textarea {
    line-height: 2rem;
}

a {
    text-decoration: none;
    outline: none;
    color: $color-pacific-blue;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &[disabled] {
        opacity: 0.8;
        color: $color-gray-text;
        pointer-events: none;
    }
}

svg {
    vertical-align: middle;
}

::selection {
    background-color: $color-prussian-blue;
    color: $color-white;
}

h1 {
    font-size: 5rem;
    font-weight: 300;
    color: $color-primary;
}

h2 {
    font-size: 3rem;
    font-weight: 400;
    color: $color-primary;
}

h3 {
    font-size: 2rem;
    font-weight: 600;
    color: $color-primary;
}
